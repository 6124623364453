<template>
    <div class="preview-container">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <img
                        class="logo"
                        :src="pageLogo">
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="top-dots">
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                </div>
            </div>
            <div class="instagram-feed-carousel">
                <carousel
                    :per-page="1"
                    :mouse-drag="true"
                    :navigation-enabled="false"
                    :pagination-enabled="true">
                    <slide
                        v-for="(slide, index) in creatives"
                        :key="index">
                        <div class="block">
                            <div class="image-holder">
                                <div
                                    v-if="slide.media_type === 'VIDEO'"
                                    :ref="'video-icon'+index"
                                    class="play-wrapper"
                                    @click="playVideo(index)">
                                    <div class="play">
                                        <icon
                                            size="40"
                                            color="white"
                                            name="audio-play" />
                                    </div>
                                </div>
                                <video
                                    v-if="slide.media_type === 'VIDEO'"
                                    :ref="'video'+index"
                                    class="preview-video"
                                    controls>
                                    <source
                                        :src="slide.media_url"
                                        type="video/mp4">
                                </video>
                                <img
                                    v-else
                                    class="preview-image"
                                    :src="slide.media_url">
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="learn">
                <a
                    :href="ctaLink"
                    target="_blank">{{ ctaText }}</a>
            </div>
            <div class="social-icons">
                <img
                    src="/img/instagram-icons.png"
                    alt="icons">
            </div>
            <div class="description">
                <span class="description-title">sarasota_ford</span>
                <span>{{ sanitizedPrimaryText }}</span>
                <span
                    v-if="!showmore"
                    class="showmore"
                    @click="showmore = true">more</span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { Carousel, Slide } from 'vue-carousel';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { FACEBOOK, primaryText as primaryTextKey } from '@/components/ad-studio/store/constants';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        Icon,
        Carousel,
        Slide,
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
    },
    data() {
        return {
            desc: '',
            showmore: false,
            defaultLogoUrl: 'https://www.facebook.com/images/fb_icon_325x325.png',
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        assetType() {
            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        sanitizedPrimaryText() {
            const primaryText = this.adData?.primary_text || this.creatives[0]?.title; // todo check if this should be headline or title
            return primaryText?.slice(0, lengthValidatorConstants[primaryTextKey][FACEBOOK].maxLength);
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives?.cta_text;
        },
    },
    watch:{
        showmore() {
            if(this.showmore === true) {
                this.truncateDescription(this.sanitizedPrimaryText, true);
            }
        }
    },
    mounted() {
        this.truncateDescription(this.sanitizedPrimaryText, false);
    },
    methods: {
        truncateDescription(desc, showAll) {
            if (desc?.length > 65 && !showAll) {
                this.desc = desc.slice(0, 65) + '...';
            }  else {
                this.desc = this?.adData?.body_text;
            }
        },
        playVideo(i) {
            const videoRef = 'video'+i;
            const iconRef = 'video-icon'+i;
            this.$refs[videoRef][0].play();
            this.$refs[iconRef][0].style.display = 'none';
        },
    }
};
</script>

<style lang="scss" scoped>
.preview-container{
    position: relative;
    width: 375px;
    display: flex;
    flex-direction: column;
    background-color:$white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 599px) {
        width: 375px;
    }
    @media only screen and (max-width: 400px) {
        width: 320px;
    }
}
.preview-ui{
    position: relative;
    display: flex;
    height:100%;
    flex-direction: column;
}
.top-elements{
    border-top: 1px solid #DDDDDD;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
}
.top-dots{
    margin-top:3px;
    white-space: nowrap;
}
.top-dot{
    display: inline-block;
    margin-left: 5px;
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.top-info{
    display: flex;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top:2px;
}
.top-sponsored{
    font-size: 13px;
    color: $gray;
}
.description{
    font-size: 18px;
    padding: 16px;
    color:black;
    overflow: hidden;
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 18px;
    padding-bottom: 30px;
    line-height: 22px;
}
.description-title{
    font-weight: 700;
    margin-right: 10px;
}
.showmore{
    margin-left: 15px;
    font-weight: 600;
    color: $gray;
    cursor: pointer;
    display: inline-block;
}
.block{
    width: 100%;
    box-sizing: border-box;

}
.image-holder{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 500px;
    background:black;
    img, video{
        display: inline-block;
        max-width: 100%;
        // max-height: 500px;
        height: auto;
        vertical-align: top;
        @media only screen and (max-width: 599px) {
        max-height: 375px;
        }
        @media only screen and (max-width: 400px) {
        max-height: 320px;
        }
    }
    @media only screen and (max-width: 599px) {
       min-height: 375px;
    }
    @media only screen and (max-width: 400px) {
       min-height: 320px;
    }
}

.learn{
    padding: 16px;
    border-bottom: 1px solid #F1F1F2;
    a{
        color:black;
        text-decoration: none;
        font-weight: 700;
        font-size: 20px;
    }
}

.social-icons{
    padding: 16px 16px 0 16px;
    img{
        width: 112px;
        height: auto;
        margin-left: 12px;
        margin-top:5px;
        @media only screen and (max-width: 599px) {
            width: 90px;
            margin-left: 0;
        }
    }
}

.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}

</style>

<style lang="scss">
.instagram-feed-carousel{
    .VueCarousel-pagination{
        position: absolute;
        bottom:-110px;
    }
    .VueCarousel-dot{
        padding: 3px !important;
        width: 12px !important;
        height: 12px !important;
        background-color: #D8D8D8 !important;
    }
    .VueCarousel-dot--active{
        background-color: #6EADFF !important;
    }
}

</style>
